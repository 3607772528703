import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import HeroImage from 'components/hero-image'
import H2 from 'components/typography/h2'
import Button from 'components/button/button'
import Promos from 'components/promos'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import Content from 'components/content'
import Section from 'components/section'
import { GatsbyImage } from 'gatsby-plugin-image'
import FeaturesGrid from 'components/features-grid'

const ServicePage = ({
  data: { agronomyImage, allHutsonPromotion, heroImage, powergardImage, sentryImage },
}) => {
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => {
      promos.push(node)
    })
  }
  return (
    <Layout>
      <Helmet>
        <title>Service | Hutson Inc</title>
        <meta
          name='description'
          content='Hutson is committed to keeping your equipment up and running. You can trust our service department for all your maintenance and repairs, in the shop or in the field.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Service',
                'item': 'https://www.hutsoninc.com/service/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroImage title='Service' image={heroImage}>
        <Button as={ScheduleServiceLink} to='/service/schedule-service/'>
          Schedule a Service
        </Button>
      </HeroImage>

      <Content>
        <H2>Hutson Customer Portal</H2>
        <p>
          Imagine being able to see past transactions, easy parts ordering, and being able to check
          our parts inventory without driving to the dealership. Now you can with the Hutson
          Customer Portal at Hutson.
        </p>
        <Button as={StyledLink} to='/hutson-customer-portal/' color='green' ghost>
          Learn More about the Hutson Customer Portal
        </Button>

        <Section>
          <H2>Why Service with Hutson?</H2>
          <p>
            We're committed to keeping you up and running. We take a lot of pride in the John Deere
            name, we know how good the equipment is, but it needs to be serviced regularly and
            repaired sometimes. And when you get a service or repair done, you expect it to be done
            right the first time. We have over 120 John Deere trained technicians on staff to ensure
            that. Our parts warehouse’s are stocked so your machine isn't waiting on parts to arrive
            which means you get back out in the field or back to mowing your yard faster.
          </p>
          <p>
            For Farmers, getting equipment to our shop can be costly and time-consuming. Especially
            during planting and harvest season. We have over 50 road technicians fully equipped
            field service trucks to get to you when and where you need it most. These trucks are
            loaded with air compressors, generators, cutting torches, jacks, cranes, you name it to
            get the job done.
          </p>
        </Section>

        <Promos data={promos} type='Service' />
      </Content>

      <FeaturesGrid
        features={[
          {
            image: sentryImage.childImageSharp.gatsbyImageData,
            imageAlt: 'Family standing in front of their house beside a John Deere Gator',
            title: 'Hutson Assurance through Sentry Insurance',
            copy: 'Farmers nationwide rely on Sentry Insurance every day. Find out why Sentry is the largest writer of John Deere dealerships.',
            button: {
              link: '/sentry/',
              text: 'Learn more about Sentry Insurance',
            },
          },
          {
            image: powergardImage.childImageSharp.gatsbyImageData,
            imageAlt: 'Key with keychain that says "PowerGard"',
            title: 'PowerGard Protection Plan',
            copy: 'Protect yourself from unexpected repairs and keep your maintenance up to date with genuine parts with the PowerGard Protection Plan.',
            button: {
              link: '/powergard/',
              text: 'Learn more about PowerGard',
            },
          },
        ]}
      />

      <AgronomyContainer>
        <AgronomyBackground>
          <GatsbyImage
            image={agronomyImage.childImageSharp.gatsbyImageData}
            objectFit='cover'
            objectPosition='50% 50%'
            alt='John Deere combine'
            style={{ width: '100%', height: '100%' }}
          />
        </AgronomyBackground>
        <AgronomyContent>
          <h4>Hutson's Precision Ag Services</h4>
          <Button as={StyledLink} to='/precision-ag/' color='yellow' ghost>
            Learn More about Hutson's Precision Team
          </Button>
        </AgronomyContent>
      </AgronomyContainer>
    </Layout>
  )
}

const StyledLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
`

const ScheduleServiceLink = styled(StyledLink)`
  display: inline-block;
  margin-top: 10px;
  width: 200px;

  button {
    width: 100%;
  }
`

const AgronomyContainer = styled.div`
  position: relative;
  height: 300px;

  @media ${props => props.theme.mediaQuery.mediumUp} {
    height: 500px;
  }
`

const AgronomyBackground = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 0;
`

const AgronomyContent = styled(Content)`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  h4 {
    color: #fff;
    font-weight: 600;
    margin-bottom: 30px;
    text-align: left;
    text-transform: none;
  }

  @media ${props => props.theme.mediaQuery.mediumUp} {
    h4 {
      text-align: right;
    }

    ${StyledLink} {
      float: right;
    }
  }
`

export const pageQuery = graphql`
  {
    heroImage: file(relativePath: { eq: "service/service-page-bg.jpg" }) {
      ...FullWidthImage
    }
    allHutsonPromotion(
      filter: { tags: { in: ["service"] } }
      sort: { fields: [endDate], order: DESC }
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    agronomyImage: file(relativePath: { eq: "service/ag-data.jpg" }) {
      ...FullWidthImage
    }
    sentryImage: file(relativePath: { eq: "service/sentry-family.jpg" }) {
      ...SharpImage900
    }
    powergardImage: file(relativePath: { eq: "service/powergard.jpg" }) {
      ...SharpImage900
    }
  }
`

export default ServicePage
